@font-face {
    font-family: 'Basier Circle';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local(''),
         url('../../Fonts/BasierCircle-Regular.ttf') format('truetype'), /* Safari, Android, iOS */
  }
@font-face {
    font-family: 'Basier Circle';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: local(''),
         url('../../Fonts/BasierCircle-Medium.ttf') format('truetype'), /* Safari, Android, iOS */
  }
@font-face {
    font-family: 'Basier Circle';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: local(''),
         url('../../Fonts/BasierCircle-SemiBold.ttf') format('truetype'), /* Safari, Android, iOS */
  }
@font-face {
    font-family: 'Basier Circle';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local(''),
         url('../../Fonts/BasierCircle-Bold.ttf') format('truetype'), /* Safari, Android, iOS */
  }