@import '../../Assets/Styles/util';

.dashboard {
    background-color: $dashboard-background;
    min-height: calc(100vh - 120px);
    display: flex;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 15px;
    gap: 15px;
}