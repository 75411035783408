@import '../../Assets/Styles/util';

.spinner__container {
  background-color: $dashboard-background;
  min-height: calc(100vh - 120px);
  display: flex;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 15px;
  gap: 15px;
  align-items: center;
  justify-content: center;
}

.panel .spinner__panel {
  background-color: $white;
  box-shadow: $theme-shadow;
  padding: 60px 60px;
}

.spinner__panel {
  margin-top: -10%;
  flex-shrink: 0;
  border-radius: 10px;
  text-align: center;
}

.spinner__panel__title {
  padding: 20px;
  padding-top: 0px;
}

.spinner__panel__title__text {
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  letter-spacing: -0.025em;
  color: rgba(62, 0, 91, 0.79);
  padding-bottom: 5px;
}

.spinner__spinner {
  align-items: center;
  justify-content: center;
}
