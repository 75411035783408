@import '../../Assets/Styles/util';

.dashboard__main--distributions {
  background-color: $white;
  height: 100%;
  border-radius: 5px;
  box-shadow: $theme-shadow;
  padding: 30px;
}

.dashboard__main--distributions-title {
  font-weight: 700;
  font-size: 32px;
  line-height: 120%;
  color: $color-title;
  margin-bottom: 40px;
}

.dashboard__main--distributions-list {
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
  list-style: none;
}

.dashboard__main--distributions-list-item {
  position: relative;
  width: 352px;
  height: 276px;
  background-color: $white;
  border-radius: 5px;
  box-shadow: $theme-shadow;
  padding: 15px;
  cursor: pointer;
  a {
    text-decoration: none;
  }
  &.create-distribution.disabled {
    border: 1px dashed #ccc;
  }
  &.create-distribution {
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px dashed $theme-color;
    .dashboard__main--distributions-list-item--icon.disabled,
    .dashboard__main--distributions-list-item-title.disabled {
      filter: grayscale(1) invert(1);
    }
    .dashboard__main--distributions-list-item--icon {
      margin-bottom: 20px;
    }
    .dashboard__main--distributions-list-item-title {
      font-weight: 700;
      font-size: 24px;
      line-height: 120%;
      color: $theme-color;
    }
  }
  .dashboard__main--distributions-list-item-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .dashboard__main--distributions-list-item-title {
      color: $color-dist-title;
      font-weight: 700;
      font-size: 20px;
      line-height: 120%;
    }
    .dashboard__main--distributions-list-item-status {
      display: flex;
      gap: 10px;
      .item-status {
        display: block;
        width: 14px;
        height: 14px;
        border-radius: 50%;
        background-color: black;
        &.disabled {
          background-color: $color-status-red;
        }
        &.in_progress {
          background-color: $color-status-orange;
        }
        &.enabled {
          background-color: $color-status-green;
        }
      }
      .status-text {
        color: $color-dist-title;
        font-weight: 700;
        font-size: 15px;
        line-height: 100%;
      }
    }
  }
  .dashboard__main--distributions-list-item--dist-icon {
    width: 100%;
    text-align: center;
    margin: 15px 0;
  }
  .dashboard__main--distributions-list-item--info {
    .dashboard__main--distributions-list-item--info-title {
      font-weight: 500;
      font-size: 18px;
      line-height: 158%;
      color: $color-dist-title;
    }
    .dashboard__main--distributions-list-item--info-content {
      font-weight: 700;
      font-size: 20px;
      line-height: 158%;
      color: $color-dist-content;
    }
  }
}
