@import '../Assets/Styles/util';

.line-chart-container {
  max-width: 500px;
  width: 100%;
  box-shadow: $chart-shadow;
  overflow: hidden;
  padding-bottom: 18px;
}

.line-chart__upper {
  padding: 5px 18px;
  border-radius: 5px;
  box-shadow: $theme-shadow;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  color: $color-dist-title;
  > span {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0px 5px 12px rgba(0, 0, 0, 0);
    transition: 0.2;
    cursor: pointer;
    &:hover {
      box-shadow: 0px 5px 12px rgba(0, 0, 0, 0.15);
    }
    &.active {
      color: #e84f1c;
    }
  }
  .margin-left {
    margin-left: auto;
  }
}

.pi-18 {
  padding-inline: 18px;
}
