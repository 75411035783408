@import '../../Assets/Styles/util';

.header {
    background-color: $header-background;
    color: $white;
    height: 95px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
}