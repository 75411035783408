@import '../../Assets/Styles/util';

.billing__title {
  color: $header-background;
  font-weight: 700;
  font-size: 32px;
  line-height: 120%;
  margin-bottom: 20px;
}

.billing__subtitle {
  color: $header-background;
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  margin-bottom: 20px;
}
.billing__h4 {
  color: $header-background;
  font-weight: 700;
  font-size: 20px;
  line-height: 120%;
  max-width: 200px;
  align-self: start;
  min-height: 48px;
}

.input-col-2 {
  display: flex;
  width: 100%;
  gap: 30px;
}

.billing__form {
  max-width: 750px;
  .input-wrap {
    width: 100%;
    .input {
      &[type='text'] {
        max-width: 100%;
      }
    }
  }
}

.billing__pie-chart-wrapper {
  display: flex;
  gap: 25px;
  margin-bottom: 25px;
  .billing__pie-chart {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
    max-width: 340px;
    width: 100%;
    max-height: 400px;
    background: rgba(242, 232, 245, 0.35);
    box-shadow: inset 0px 0px 17px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    &.small {
      justify-content: start;
      height: auto;
    }
  }
}

.billing__flex-between {
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  color: #faba00;
  margin-bottom: 10px;
}

.billing__price {
  font-weight: 700;
  font-size: 32px;
  line-height: 120%;
  color: #faba00;
  margin-bottom: 20px;
}

.billing__stripe-wrapper {
  width: 635px;
  height: 285px;
  box-shadow: $theme-shadow;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  text-decoration: none;
  color: $color-title;
  .title {
    font-weight: 700;
    font-size: 64px;
    line-height: 120%;
    margin-top: 25px;
    margin-bottom: 10px;
  }
}
