@import 'react-datepicker/dist/react-datepicker.css';

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: #676767;
  font-weight: 400;
}

.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled,
.react-datepicker__year-text--disabled {
  color: #ccc;
}

.react-datepicker__day-name {
  font-weight: 600;
}

.react-datepicker {
  color: #e84f1c !important;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  color: #e84f1c !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background-color: #e84f1c !important;
  color: #fff;
}

.react-datepicker__header {
  background-color: #fff;
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker__navigation-icon::before {
  border-color: #e84f1c !important;
}

.react-datepicker__close-icon::after {
  background-color: transparent;
  vertical-align: bottom;
  width: 8px;
}

.react-datepicker__close-icon {
  display: none;
}
