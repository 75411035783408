@import '../../Assets/Styles/util';

.dashboard__sidebar {
  background-color: $white;
  flex-shrink: 0;
  max-width: 270px;
  width: 100%;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: $theme-shadow;
  padding: 30px 0px;
}

.dashboard__sidebar-nav {
  .dashboard__sidebar-nav-list {
    list-style: none;
    .dashboard__sidebar-nav-list-item {
      position: relative;
      display: flex;
      align-items: center;
      gap: 25px;
      cursor: pointer;
      margin-bottom: 10px;
      .dashboard__sidebar-sublist {
        display: none;
      }
      .dashboard__sidebar-nav-list-item-link {
        height: 42px;
        padding-left: 30px;
        width: 100%;
        display: flex;
        align-items: center;
        gap: 25px;
        text-decoration: none;
        &.active {
          background-color: rgba($theme-color, 0.06);
          .dashboard__sidebar-nav-list-item-text {
            font-size: 20px;
            font-weight: 700;
            color: $theme-color;
          }
          .dashboard__sidebar-nav-list-item-icon {
            fill: $theme-color;
            &.globe {
              fill: none;
              path {
                stroke: $theme-color;
              }
            }
          }
          &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            width: 7px;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
            background-color: $theme-color;
          }
          &::after {
            content: '';
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            width: 7px;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
            background-color: $theme-color;
          }
        }
      }
      .dashboard__sidebar-nav-list-item-icon {
        fill: $color-sidebar;
        &.globe {
          fill: none;
          path {
            stroke: $color-sidebar;
          }
        }
      }
      .dashboard__sidebar-nav-list-item-text {
        font-weight: 500;
        font-size: 18px;
        line-height: 140%;
        color: $color-sidebar;
      }
    }
  }
  .dashboard__sidebar-sublist {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-bottom: 10px;
    li {
      list-style-type: none;
    }
  }
  .dashboard__sidebar-sublist-item-link {
    position: relative;
    display: block;
    width: 220px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    text-decoration: none;
    color: $color-sidebar;
    &.active {
      background-color: rgba($theme-color, 0.06);
      &::after {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        width: 7px;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        background-color: $theme-color;
      }
    }
    .dashboard__sidebar-sublist-item-name {
      font-weight: 700;
      font-size: 16px;
      line-height: 112%;
      color: $color-sidebar;
      padding: 5px 0px 5px 30px;
      cursor: pointer;
      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 20px;
        transform: translate(-100%, -50%);
        display: block;
        width: 11px;
        height: 11px;
        border-radius: 50%;
        background-color: transparent;
      }
      &.enabled {
        &:before {
          background-color: $color-status-green;
        }
      }
      &.in_progress {
        &:before {
          background-color: $color-status-orange;
        }
      }
      &.disabled {
        &:before {
          background-color: $color-status-red;
        }
      }
    }
  }
}
.dashboard__sidebar-btn--create {
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 246px;
  width: 100%;
  margin: 0 auto;
  border: none;
  background-color: $theme-color;
  font-family: $font-family;
  color: $white;
  border-radius: 5px;
  font-weight: 700;
  font-size: 16px;
  line-height: 132%;
  transition: 0.3s;
  cursor: pointer;
  a {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: $font-family;
    color: $white;
    text-decoration: none;
  }
  .dashboard__sidebar-btn--create-text {
    width: 115px;
  }
  &:hover {
    background-color: darken($theme-color, 5%);
  }
}
