@import '../../Assets/Styles/util';

.input-wrap .input[type=checkbox] {
    appearance: none;
    max-width: 16px;
    width: 100%;
    height: 16px;
    background-color: #fff;
    outline: 2px solid #e84f1c;
    border: 4px solid #fff;
    transition: 0.2s all linear;
    margin-right: 5px;
    cursor: pointer;
}

.input-wrap .input[type=checkbox]:disabled {
    outline: 2px solid #808080;
}