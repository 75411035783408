.trial-bar {
  background-color: rgb(250, 186, 0);
  text-align: center;
  font-size: 14px;
  padding: 6px;
  padding-left: 25px;
  a {
    color: #000;
  }
}
