@import './util';
@import './dashboard.scss';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: $font-family;
  font-size: 18px;
  overflow-y: scroll;
}

.flashedge-app {
  min-height: 100vh;
}

.mb-40 {
  margin-bottom: 40px;
}
.ml-auto {
  margin-left: auto;
}
.ml-auto-20 {
  margin-left: 20px;
}
.ml-auto-10 {
  margin-left: 10px;
}

button {
  &:disabled,
  &:disabled:hover {
    color: $white !important;
    background-color: $disabled-dark !important;
    border: 1px solid $disabled-light !important;
  }
}

.theme-btn {
  display: inline-block;
  padding: 10px 25px;
  background-color: $theme-color;
  text-decoration: none;
  color: $white;
  border-radius: 5px;
  font-family: $font-family;
  font-weight: 700;
  font-size: 16px;
  line-height: 132%;
  transition: 0.3s;
  cursor: pointer;
  border: 1px solid $theme-color;
  margin-bottom: 20px;
  box-shadow: $table-shadow;
  &.inverted {
    background-color: $white;
    color: $theme-color;
    border: 1px solid $theme-color;
    margin-left: 8px;
    &:disabled,
    &:disabled:hover {
      color: $white;
      background-color: $disabled-dark;
    }
    &:hover {
      background-color: darken($white, 5%);
    }
  }
  &:hover {
    background-color: darken($theme-color, 5%);
    border: 1px solid darken($theme-color, 5%);
  }
  &:disabled {
    background-color: $disabled-dark;
    border: 1px solid $disabled-light;
  }
}
.theme-btn-table {
  display: inline-block;
  padding: 5px 15px 5px;
  background-color: $theme-color;
  text-decoration: none;
  color: $white;
  border-radius: 5px;
  font-family: $font-family;
  font-weight: 700;
  font-size: 14px;
  line-height: 132%;
  transition: 0.3s;
  cursor: pointer;
  border: 1px solid $theme-color;
  box-shadow: $table-shadow;
  &.inverted {
    background-color: $white;
    color: $theme-color;
    border: 1px solid $theme-color;
    margin-left: 4px;
    &:hover {
      background-color: darken($white, 5%);
    }
  }
  &:hover {
    background-color: darken($theme-color, 5%);
    border: 1px solid darken($theme-color, 5%);
  }
}

.react-select-container {
  box-shadow: $theme-shadow;
  border: 0.5px solid #d6d6d6;
  border-radius: 8px;
  max-width: 350px;
  outline: none;
}

.react-select-container > div > div > div {
  padding: 10px 10px;
  color: #505050;
  font-family: $font-family;
  font-size: 16px;
  font-weight: 500;
}

.react-select-container > * {
  z-index: 100 !important;
}

.chart-containers {
  display: flex;
  gap: 50px;
  margin-bottom: 20px;
}

/* SPINNER */

.form-btn {
  // Display
  display: inline-flex;
  align-items: center;
  justify-content: center;
  // Optional - see "Gotchas"
  align-self: start;

  // Size
  &:not(.icon-button) {
    padding: 0.25em 0.75em;
    min-width: 10ch;
    min-height: 44px;
  }

  // Text
  text-align: center;
  line-height: 1.1;

  transition: 220ms all ease-in-out;

  /*&:hover,
  &:active {
    background-color: darken($theme-color, 5%);
  }*/

  /*&:focus {
    outline: none;
    box-shadow: 0 0 0 4px scale-color($btnColor, $lightness: -40%);
  }*/

  &--small {
    font-size: 1.15rem;
  }

  & svg {
    margin-right: 10px;
  }
}

fieldset {
  border: 0px;
}

.spinner {
  animation: rotate 2s linear infinite;
  z-index: 2;

  & .path {
    stroke: hsl(210, 70, 75);
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
