@import "../Assets/Styles/util";

.pie-chart-container {
    max-width: 120px;
    width: 100%;
    align-self: center;
}

.pie-chart-indicator {
    display: block;
    margin-bottom: 15px;
    margin-top: 15px;
    text-align: left;
    font-weight: 700;
    font-size: 18px;
    line-height: 120%;
    color: #FABA00;
    max-width: 50%;
    align-self: flex-start;
    word-wrap: normal;
}