@import '../../Assets/Styles/util';

.chart-stats {
  max-width: 530px;
  width: 100%;
  box-shadow: $theme-shadow;
  padding: 15px 25px;
  display: grid;
  justify-content: space-between;
  gap: 0 25px;
  grid-template-columns: repeat(3, 1fr);
  grid-row-gap: 10px;
  margin-bottom: 20px;
  border-radius: 5px;
}
.chart-stats__col {
  display: flex;
  flex-direction: column;
  /*justify-content: space-between;*/
  justify-content: center;
  gap: 10px;
}
.chart-stats__price {
  color: $theme-color;
  font-weight: 700;
  font-size: 32px;
  line-height: 120%;
}
.chart-stats__text {
  color: $color-dist-title;
  font-weight: 700;
  font-size: 20px;
  line-height: 120%;
}
.chart-stats__header {
  color: $theme-color;
  font-weight: 700;
  font-size: 18px;
  line-height: 122%;
  margin-top: 8px;
}
