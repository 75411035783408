@import './util';

.dashboard__main-wrapper {
  background-color: $white;
  height: 100%;
  border-radius: 5px;
  box-shadow: $theme-shadow;
  padding: 30px;
}

.dashboard__main-form {
  max-width: 750px;
  width: 100%;
}

.dashboard__separator {
  height: 1px;
  margin-top: 27px;
  margin-bottom: 25px;

  &::before {
    content: '';
    position: absolute;
    background-color: #c9c9c9;
    width: 100%;
    height: 1px;
    left: 0;
  }
}

.content-title {
  font-weight: 700;
  font-size: 32px;
  line-height: 120%;
  letter-spacing: -0.025em;
  color: $color-title;
  margin-bottom: 10px;
}

.content-title-in {
  font-weight: 700;
  font-size: 28px;
  line-height: 120%;
  letter-spacing: -0.025em;
  color: $color-title;
  margin-bottom: 40px;
  margin-top: 25px;
}

.content-subtitle {
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  letter-spacing: -0.025em;
  color: $color-title;
  margin-top: 20px;
  margin-bottom: 20px;
}

.content-text {
  font-weight: 500;
  font-size: 20px;
  line-height: 158%;
  color: $color-dist-title;
  margin-bottom: 20px;
  .warning {
    color: $theme-color;
    font-weight: 700;
  }
  .input-label {
    font-size: 16px;
    font-weight: 400;
    color: $color-dist-title;
    .bold {
      font-weight: 700;
    }
  }
}

.font-size-18 {
  font-size: 18px;
}

.content-bold {
  font-weight: 700;
}

.content-text-600 {
  font-weight: 600;
  font-size: 20px;
  line-height: 158%;
  color: #9696a0;
  margin-bottom: 40px;
}

.time-to-live {
  display: flex;
  align-items: center;
  text-transform: capitalize;
  gap: 25px;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: $color-dist-title;
  .time-to__box {
    position: relative;
    display: flex;
    align-items: center;
    gap: 25px;
    .time-to__input {
      font-family: $font-family;
      appearance: none;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #ffc2b0;
      border-radius: 5px;
      width: 80px;
      height: 40px;
      font-weight: 700;
      text-align: center;
      padding-left: 15px;
      line-height: 18px;
      border: none;
      color: $color-dist-title;
      font-size: 16px;
      &:focus {
        outline: none;
      }
    }
  }
}

.input-wrap {
  position: relative;
  z-index: 20;
  display: flex;
  flex-direction: column;
  gap: 7px;
  margin-bottom: 20px;
  &.select-input {
    z-index: 30;
  }
  .input-label {
    display: block;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    color: $color-dark-fonts;
    padding-left: 2px;
  }
  .input {
    &[type='text'] {
      padding: 15px 20px;
      border-radius: 8px;
      box-shadow: $theme-shadow;
      border: 0.5px solid #d6d6d6;
      max-width: 350px;
      outline: none;
      font-family: $font-family;
      font-size: 16px;
      font-weight: 500;
      color: $color-nav-fonts;
    }
    &[type='password'] {
      padding: 15px 20px;
      border-radius: 8px;
      box-shadow: $theme-shadow;
      border: 0.5px solid #d6d6d6;
      max-width: 350px;
      outline: none;
      font-family: $font-family;
      font-size: 16px;
      font-weight: 500;
      color: $color-nav-fonts;
    }
    &[type='radio'] {
      appearance: none;
      border-radius: 50%;
      max-width: 16px;
      width: 100%;
      height: 16px;

      background-color: $white;
      outline: 2px solid $theme-color;
      border: 4px solid $white;
      transition: 0.2s all linear;
      margin-right: 5px;
      cursor: pointer;
      &:checked {
        background-color: $theme-color;
        border: 3px solid white;
      }
    }
    &.md {
      max-width: 500px;
    }
  }
  &.radio-btn {
    flex-direction: row;
    align-items: center;
    input {
      &:checked + label {
        .bold-selected {
          font-weight: 700;
        }
      }
    }
    .input-label {
      font-size: 16px;
      font-weight: 400;
      color: $color-dist-title;
      .bold {
        font-weight: 700;
      }
    }
  }
  .input--select {
    max-width: 350px;
    width: 100%;
    padding: 15px 20px;
    border-radius: 8px;
    box-shadow: $theme-shadow;
    border: 0.5px solid #d6d6d6;
    color: #585858;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
  }
  .input--select-custom {
    position: relative;
    max-width: 350px;
    width: 100%;
    box-shadow: $theme-shadow;
    border-radius: 8px;
    &.active {
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
      .input--select-show {
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        border-bottom: 0.5px solid transparent;
        &:before {
          transform: translateY(-50%) rotate(180deg);
        }
      }
      .input--select-list {
        display: block;
      }
    }
    .input--select-show {
      display: block;
      width: 100%;
      padding: 17px 20px;
      border-radius: 8px;
      border: 0.5px solid #d6d6d6;
      color: #585858;
      font-weight: 400;
      font-size: 16px;
      line-height: 16px;
      cursor: pointer;
      &:before {
        content: '';
        position: absolute;
        z-index: 5;
        top: 24px;
        right: 15px;
        transform: translateY(-50%) rotate(0deg);
        width: 0;
        height: 0;
        border-left: 12px solid transparent;
        border-right: 12px solid transparent;
        border-top: 12px solid $theme-color;
        transition: 0.2s ease;
      }
    }
    .input--select-list {
      display: none;
      position: absolute;
      bottom: 0;
      left: 0;
      transform: translateY(100%);
      width: 100%;
      border-radius: 8px;
      border: 0.5px solid #d6d6d6;
      box-shadow: 5px 15px 12px rgba(0, 0, 0, 0.15);
      color: #585858;
      font-weight: 400;
      font-size: 16px;
      line-height: 16px;
      background-color: $white;
      border-top: none;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      overflow: hidden;
      .input--select-item {
        display: block;
        padding: 15px 20px;
        cursor: pointer;
        transition: 0.2s ease;
        &:hover {
          background-color: #f1eded;
        }
      }
    }
  }
  .input-pill-tags {
    border-radius: 8px;
    box-shadow: $theme-shadow;
    border: 0.5px solid #d6d6d6;
    padding: 12px 20px;
    max-width: 350px;
    width: 100%;
    .input-pill__list {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      list-style: none;
      gap: 12px;
      .input-pill__item {
        display: flex;
        justify-content: center;
        text-align: center;
        border-radius: 3px;
        background-color: #ffc2b0;
        font-weight: 500;
        font-size: 16px;
        line-height: 132%;
        padding: 3px 5px;
        color: #252525;
        .input-pill__item-delete {
          margin-left: 3px;
          line-height: 0;
          cursor: pointer;
        }
      }
      .input-pill__wrap {
        display: flex;
        flex: 1;
        width: 100%;
        .input-pill__btn-add {
          position: relative;
          background-color: $theme-color;
          line-height: 0;
          border-radius: 3px;
          border: none;
          outline: none;
          max-width: 28px;
          min-width: 28px;
          height: 28px;
          cursor: pointer;
          &:hover {
            background-color: darken($theme-color, 5%);
          }
          &:before {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            height: 2px;
            width: 16.33px;
            background-color: $white;
            border-radius: 5px;
          }
          &:after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(90deg);
            height: 2px;
            width: 16.33px;
            background-color: $white;
            border-radius: 5px;
          }
        }
        .input-pill__input {
          font-family: $font-family;
          font-size: 16px;
          font-weight: 500;
          height: 28px;
          width: 100%;
          min-width: 50px;
          outline: 0;
          border: 0;
        }
      }
    }
  }
}

.table-wrapper {
  background-color: $white;
  border-radius: 5px;
  box-shadow: $theme-shadow;
  overflow: hidden;
  border-collapse: collapse;
  border: none;
  margin-top: 30px;
  margin-bottom: 20px;
  max-width: 500px;
  width: 100%;
  font-size: 16px;
}

.table-https-wrapper {
  margin-top: 30px;
  margin-bottom: 20px;
}

.table-head {
  background-color: $header-background;
  color: $white;
  border-radius: 5px;
  box-shadow: $table-shadow;
  &.table-head-row {
    border-radius: 5px;
  }
  tr {
    th {
      white-space: nowrap;
      padding: 10px 45px;
      &:first-child {
        border-bottom-left-radius: 5px;
      }
      &:last-child {
        border-bottom-right-radius: 5px;
      }
    }
  }
}

.table-body {
  overflow-wrap: break-word;
  &.same-color {
    tr {
      color: #232d42;
      font-weight: 400;
      &:nth-child(even) {
        background-color: none;
        box-shadow: none;
      }
    }
  }
  tr {
    color: #545454;
    font-size: 16px;
    line-height: 140%;
    font-weight: 500;
    text-align: center;
    border-radius: 5px;
    &:nth-child(even) {
      background-color: rgba(61, 0, 92, 0.15);
      box-shadow: $table-shadow;
      td {
        &:first-child {
          border-bottom-left-radius: 5px;
          border-top-left-radius: 5px;
        }
        &:last-child {
          border-bottom-right-radius: 5px;
          border-top-right-radius: 5px;
        }
      }
    }
    td {
      max-width: 256px;
      width: 100%;
      min-width: 180px;
      padding: 15px 10px;
    }
  }
}
