@import '../../Assets/Styles/util';

.sms__container {
  background-color: $dashboard-background;
  min-height: calc(100vh - 120px);
  display: flex;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 15px;
  gap: 15px;
  align-items: center;
  justify-content: center;
}

.panel .sms__panel {
  background-color: $white;
  box-shadow: $theme-shadow;
  padding: 60px 60px;
}

.sms__panel {
  margin-top: -10%;
  flex-shrink: 0;
  border-radius: 10px;
  text-align: center;
}

.sms__panel input {
  max-width: 100% !important;
}

.left {
  text-align: left;
}
