.bottom-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 25px;
  background-color: #3d005c;
  color: #3d005c;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 100px;
  z-index: 9999;
}

.bottom-links a {
  margin-left: 25px;
  margin-top: 2px;
  margin-bottom: 2px;
  font-weight: 400;
  color: #d8ccde;
  text-decoration: none;
  font-size: 16px;
}
