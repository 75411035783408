$header-background: #3d005c;
$theme-color: #e84f1c;
$white: #fff;
$dashboard-background: #f4ecf8;
$color-sidebar: #505050;
$color-status-green: #2ca02c;
$color-status-orange: #ff9900;
$color-status-red: #ff0000;
$color-nav-fonts: #2f2f2f;
$color-title: rgba(#3e005b, 0.79);
$color-dist-title: #676767;
$color-dist-content: #faba00;
$color-dark-fonts: #252525;
$disabled-dark: #808080;
$disabled-light: #dddddd;

$font-family: 'Basier Circle', sans-serif;

$theme-shadow: 0px 5px 12px rgba(0, 0, 0, 0.15);
$table-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
$chart-shadow: $theme-shadow;
