@import '../../Assets/Styles/util';

.dashboard__main--navigation {
  background-color: $white;
  max-height: 80px;
  min-height: 80px;
  border-radius: 5px;
  box-shadow: $theme-shadow;
  display: flex;
  align-items: center;
  padding: 0 30px;
}

.dashboard__main--navigation-list {
  display: flex;
  list-style: none;
  gap: 50px;
  .dashboard__main--navigation-list-item {
    position: relative;
    opacity: 0.8;
    font-weight: 700;
    font-size: 16px;
    text-transform: capitalize;
    padding: 28px 0;
    cursor: pointer;
    color: $color-nav-fonts;
    text-decoration: none;
    &::before {
      content: '';
      display: block;
      position: absolute;
      bottom: -2px;
      width: 100%;
      height: 7px;
      background-color: #e84f1c;
      border-radius: 5px;
      opacity: 0;
      transition: 0.3s;
    }
    &.active {
      font-weight: 700;
      opacity: 1;
      color: $theme-color;
      &::before {
        opacity: 1;
      }
    }
  }
}
