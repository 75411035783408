@import '../../Assets/Styles/util';

.header-user {
  display: flex;
  align-items: center;
  gap: 9px;
  .header-user__image-wrapper {
    border-radius: 50%;
    line-height: 0;
    .header-user__image {
      background-color: $white;
      border-radius: 50%;
      border: 3px solid $white;
    }
  }
  .header-user__log-out-button {
    position: relative;
    z-index: 10;
    background-color: transparent;
    line-height: 0;
    border: none;
    cursor: pointer;
    .header-user__log-out-icon {
      width: 25px;
      position: relative;
      z-index: -1;
    }
  }
}
