@import '../../Assets/Styles/util';

.header-nav__list-item {
    .header-nav__list-item-link {
        display: block;
        position: relative;
        color: $white;
        text-decoration: none;
        text-transform: uppercase;
        opacity: 0.8;
        font-weight: 400;
        line-height: 132%;
        transition: 0.3s;
        cursor: pointer;
        &::before {
            content: "";
            display: block;
            position: absolute;
            bottom: -36px;
            width: 100%;
            height: 7px;
            background-color: $theme-color;
            border-radius: 5px;
            opacity: 0;
            transition: 0.3s;
        }
        &.active {
            opacity: 1;
            &::before {
                opacity: 1;
            }
        }
        &:hover {
            opacity: 1;
            &::before {
                opacity: 1;
            }
        }
    }
}