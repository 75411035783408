@import '../../Assets/Styles/util';

.button-wrapper {
    display: flex;
    max-width: 925.25px;
    width: 100%;
}

.https-table {
    display: none;
    margin-bottom: 20px;
    &.active {
        display: table;
    }
}

.table-wrapper {
    background-color: $white;
    border-radius: 5px;
    box-shadow: $theme-shadow;
    overflow: hidden;
    border-collapse: collapse;
    border: none;
    margin-top: 30px;
    margin-bottom: 40px !important;
    max-width: 500px;
    width: 100%;
    font-size: 16px;
  }
  
  .table-head {
    background-color: $header-background;
    color: $white;
    border-radius: 5px;
    box-shadow: $table-shadow;
    &.table-head-row {
      border-radius: 5px;
    }
    tr {
      th {
        white-space: nowrap;
        padding: 10px 45px;
        &:first-child {
          border-bottom-left-radius: 5px;
        }
        &:last-child {
          border-bottom-right-radius: 5px;
        }
      }
    }
  }
  