@import '../../Assets/Styles/util';

.success-title {
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  letter-spacing: -0.025em;
  color: rgba(62, 0, 91, 0.79);
  padding-bottom: 5px;
}
